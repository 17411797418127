import React from 'react'

import "./RegistrationHomepage.scss"; 
const RegistrationHomepage=()=> {
  return (
    <div className="container-fluid container-Background">
    <div className='reg-header row'>
    <h1>Registration</h1>
    
    </div>
    </div>
  )
}

export default RegistrationHomepage;